body {
  /*background: #f7f7f7;*/
  letter-spacing: 0.5px !important;
  font-weight: 200 !important;
  color: #000;
  overflow-x: auto !important;
  font-family: Assistant,"Helvetica Neue",Arial,Helvetica,sans-serif;
}

div.ui.borderless.stackable.menu a.item:hover,
div.ui.borderless.stackable.menu a.active.item,
div.ui.borderless.stackable.menu a.item:focus,
div.ui.borderless.stackable.menu a.item:active {
  background: #fff;
  color: #f19625;
}

h1, h2, h3, h4, div.header {
  font-weight: 400 !important;
}

a, a h1, a h2, a h3, a h4 {
  color: #ff8d00 !important;

}

button {
  border-radius: 2px !important;
}

.toggleButton {
  margin-bottom: 8px !important;

}

.toggleButtonActive {
  background-color: #f19625 !important;
  color: #fff !important;
}

.label {
  border-radius: 2px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.ui.orange.tiny.label {
  margin-bottom: 8px;
}

/* 2017-06-17 ie11 fix for off-centre modals */
.ui.modal {
  position: static !important;
}

/* 2020-10-09 bren wtf */
.ui.menu {
  font-family: Lato,"Helvetica Neue",Arial,Helvetica,sans-serif !important;
}

.ui.text.container {
  max-width: 900px !important;
}

.button i.icon {
  background-color: rgba(0, 0, 0, 0) !important;
}

button.ui.active.basic.toggle.button {
  background-color: rgba(255,141,0,1) !important;
}
div.ui.orange.mini.label {
  background-color: rgba(255,141,0,1) !important;
}

div.ui.borderless.stackable.menu a.active.item {
  font-weight: 700;
}

a.ui.grey.label {
  background-color:#999 !important;
  color: #fff !important;
}

a.ui.orange.label {
  color: #fff !important;
}

.frontpage-banner {
  background-image: url('./res/skillsmatch_cafe_ladies.jpg');
  min-height: 450px;
  width: 100%;
  background-size: cover;
  background-position: center 30%;
  position: relative;
}

.frontpage-banner-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: rgba(33,27,23,.65);
  z-index: 10;
  display: flex;
}
.projectbackground-banner {
  background-image: url('./res/project_background.jpg');
  min-height: 450px;
  margin: 0 auto;
}
.wanttovolunteer-banner {
  background-image: url('./res/want_to_volunteer.jpg');
  min-height: 450px;
}
.needavolunteer-banner {
  background-image: url('./res/need_a_volunteer.jpg');
  min-height: 450px;
}
.buddies-banner {
  background-image: url('./res/buddies.jpg');
  min-height: 450px;
  background-position: center;
}

.casestudies-banner {
  background-image: url('./res/casestudies/casestudiesbanner.jpg');
  min-height: 450px;
  background-position: center;
}

.casestudies-bullets .item {
  margin-bottom: 8px;
  margin-left: 8px;
}

.lightbox-clickable {
  background: none !important;
}

.lightbox-clickable img {
  opacity: 0.85;
  transition: 0.25s
}
.lightbox-clickable img:hover {
  opacity: 1
}

button.ui.button.lightbox-closer {
  padding: 0 !important;
}

.projectbackground-banner .frontpage-banner-overlay,
.wanttovolunteer-banner .frontpage-banner-overlay,
.buddies-banner .frontpage-banner-overlay,
.needavolunteer-banner .frontpage-banner-overlay
 {
  background-color: rgba(33,27,23,.35);
}


.frontpage-banner-text {
  text-align: center;
  margin: auto;
  line-height: 52px;
  font-size: 42px;
  font-weight: 300;
  color: #fff;
}

.opp-banner {
  min-height: 450px;
  width: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
}

.opp-banner-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: rgba(33,27,23,.65);
  z-index: 10;
  display: flex;
}

.opp-banner-text {
  text-align: center;
  margin: auto;
  line-height: 52px;
  font-size: 38px;
  font-weight: 200 !important;
  color: #9c9999;
  overflow: hidden;
}

.opp-banner-text h1 {
  font-weight: 200 !important;
  font-size: 64px;
  color: #fff;
}

.skill-tooltip-left {
  white-space: pre-line !important;
}
.skill-tooltip-right {
  white-space: pre-line !important;
}

.label li {
  padding-bottom: 4px;
}

.error .quill {
  border: solid 1px #f56f6d;
}

.ui.inverted.segment {
  background: #201a16;
}

.textCountExceeded {
  color: red;
}

.ql-editor {
  min-height: 180px;
}

.ql-editor ::selection {
  background-color: #f19625 !important;
}
.ql-editor strong {
  font-weight: 600;
}
.ql-active .ql-stroke, .ql-editor a {
  color: #f19625 !important;
  stroke: #f19625 !important;
}

.tinymce-content {
  font-family: "Assistant", "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-weight: 200;
}
.mce-container {
  box-shadow: none !important
}

.hidden {
  display: none;
}

.menu-logo-nohover:hover {
  background-color: #fff !important;
}

div.ui.borderless.stackable.menu {
  margin-bottom: 0em !important;
}

.ui.toggle.checkbox input:checked~.box:before,
.ui.toggle.checkbox input:checked~label:before {
    background-color: #f19625 !important;
}

a.item:hover div.header {
  color: #f19625 !important;
}

a.item:hover {
  /*background-color: #fff !important;*/
}


/* Breakpoint override for weird nav width thing */
@media only screen and (max-width: 1280px) and (min-width: 768px) {
  .ui.container.nav-container {
    width: 100% !important;
    padding-left: 8px !important;
  }
}
