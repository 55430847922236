body {
  margin: 0 auto;
  padding: 0;
  font-family: sans-serif;

}

#root {
  padding: 0;

}
